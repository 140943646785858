@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

@include spb.set-typography;

/* General styling that applies to many pages */

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: 1193px;
  width: 95vw;
}

textarea {
  height: 45px !important;
}


td {
  cursor: pointer !important;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}

/*
Header in tables
*/
th {
  color: rgb(0 0 0 / 54%) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}